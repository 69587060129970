@use "colors.scss" as basilikColors;

.shadow-solid-primary {
    box-shadow: 12px 12px 0px 0px basilikColors.$peach,
        0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.shadow-solid-tertiary {
    box-shadow: 12px 12px 0px 0px basilikColors.$lavender,
        0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.shadow-highlight {
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.4) !important;
    z-index: 999 !important;
}

.external-auth {
    width: 328px;

    &__google {
        display: flex;
        justify-content: center;
    }

    &__microsoft,
    &__linkedin {
        height: 38px;
        border: 1px solid #8c8c8c;
        font-size: 15px;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;

        &--logo {
            margin-left: 12px;
            margin-right: 12px;
            width: auto;
        }

        &--text {
            margin-right: 12px;
        }
    }

    &__microsoft {
        color: #5e5e5e;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }

    &__linkedin {
        font-family: sans-serif;
    }
}

/* Utility classes */

.text-white {
    color: white !important;
}

.svg-white {
    fill: white !important;

    svg {
        fill: white !important;
    }
}

/* mdc overwite */

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: basilikColors.$green !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: lighten(basilikColors.$green, 30%) !important;
}

.remove-mat-expansion-panel-padding
    .mat-expansion-panel-content
    .mat-expansion-panel-body {
    padding: 0 !important;
}

/* Animation keyframes */

@keyframes wiggle {
    0%,
    100% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
}

@keyframes xScroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes reward {
    0%,
    100% {
        transform: rotate(0deg) scale(1);
    }
    10% {
        transform: rotate(10deg) scale(1.2);
    }
    20% {
        transform: rotate(-10deg) scale(1.2);
    }
    30% {
        transform: rotate(10deg) scale(1.2);
    }
    40% {
        transform: rotate(-10deg) scale(1.2);
    }
    50% {
        transform: rotate(0deg) scale(1.2);
    }
}

@keyframes wizz {
    0%,
    100% {
        transform: translate(0, 0);
    }
    3.33% {
        transform: translate(10px, 0);
    }
    6.66% {
        transform: translate(-10px, 0);
    }
    10% {
        transform: translate(10px, 0);
    }
    13.33% {
        transform: translate(-10px, 0);
    }
    16.66% {
        transform: translate(10px, 0);
    }
    20% {
        transform: translate(0, 0);
    }
    99.9% {
        transform: translate(0, 0);
    }
}

// Fix buggy quilleditor css https://stackoverflow.com/questions/57695124/how-to-resize-quill-editor-to-fit-inside-a-div
.ql-container {
    height: calc(100% - 49px);
}

li,
ol {
    list-style-position: inside;
}

.w-screen-no-header {
    height: calc(100vh - 4rem);
}

.max-w-screen-no-header {
    max-height: calc(100vh - 4rem);
}
