/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;
@tailwind base;

@layer base {
    ul, ol {
      list-style: revert;
    }
  }